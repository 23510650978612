<template>
  <div id="signup">
    <SiteNav></SiteNav>
    <section>
      <div class="col2">
        <form @submit.prevent>
          <h2>Hi, {{ userProfile.name }}!</h2>
          <h5>
            Your current sex is {{ userProfile.gender }} 
            and you were born in {{ userProfile.dob }}.
          </h5>
          <div>
            <label for="name">Name</label>
            <input v-model.trim="signupForm.name" type="text" :placeholder="userProfile.name" id="name" />
          </div>
          <div>
            <label for="gender">Sex</label>
            <select v-model.trim="signupForm.gender" name="gender">
              <option disabled value="">Please select one</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="prefer">Prefer not to say</option>
            </select>
          </div>
          <div>
            <label for="age">Birth Year</label>
            <input v-model.trim="signupForm.age" type="number" :placeholder="userProfile.dob" id="age" />
          </div>
          <button @click="updateProfile()" :disabled="signupForm.name === '' || signupForm.gender === '' || signupForm.age === '' || signupForm.age < 1900 || signupForm.age > currentYear" class="button">Update</button>
        </form>
        <transition name="fade">
          <h3 v-if="showSuccess" class="success">Account updated!</h3>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SiteNav from '@/components/SiteNav'
import moment from 'moment'

export default {
  components: {
    'SiteNav': SiteNav
  },
  data() {
    return {
      showSuccess: false,
      signupForm: {
        age: '',
        gender: '',
        name: ''
      }
    }
  },
  computed: {
    ...mapState(['userProfile']),
    currentYear() {
      return moment().year()
    }
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateProfile', {
        age: this.signupForm.age,
        gender: this.signupForm.gender,
        name: this.signupForm.name
      })

      this.signupForm = {
        age: '',
        gender: '',
        name: ''
      }

      this.showSuccess = true

      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>

// colors
$primary: #3D4D62;
$secondary: #7399C6;
$error: #ef5777;
$darkGreen: #1FA482;
$lightGreen: #5CD0B5;
$lightest: #e3f2fa;
$light: #c3d6e2;
$medium: #7399C6;
$dark: #3D4D62;
$white: #FFFFFF;
$deteriorating: #ef5777;
$improving: #1FA482;
$greyedOut: #787f85;

// fonts
$primaryFont: 'Playfair Display';
$myFontSize: 18px;
$myWidth: 680px;

// signup
#signup {
  // background: $primary;

  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;

    @media screen and (max-width: 800px) {
      // display: block;
      position: relative;
      height: auto;
    }

    .col2 {
      flex: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5vh;
      width: 100%;

      h2 {
        // color: $white;
      }

      h5 {
        // color: $white;
        padding: 0 0 5vh 0;
      }
    }
  }

  .col2 {
    h1 {
      margin-bottom: 2rem;
    }

    form {
      max-width: 450px;
      margin: 0 auto;
      width: 100%;
    }

    .extras {
      float: right;
      text-align: right;
      padding: 1rem 0 0 0;

      a {
        display: block;
        margin-bottom: 0.5rem;
        color: $secondary;

        &:hover {
          color: $lightest;
        }
      }
    }
  }
}

label {
  color: $secondary;
}

h2 {
  padding: 1rem 0;
}

button {
  margin: 1rem 0;
  width: 100%;
  color: $primary;
  background: $white;
  border: 1px solid $primary;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  box-shadow: 0 0 0 0 rgba($dark, 0.5);
  cursor: pointer;

  &:hover {
    background: $lightest;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.success {
  color: $lightGreen;
  margin: auto;
}

</style>